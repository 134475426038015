export const calculatePrice = (delivery) => {
  const { deliveryTransportType, distanceInfo, pickUpTime, targetTime } = delivery;
  const fiveHoursInSeconds = 5 * 60 * 60000;
  const hasMoreThanFiveHours = targetTime - pickUpTime > fiveHoursInSeconds

  if(deliveryTransportType === 'public' && hasMoreThanFiveHours) {
    const kmsDistance = (distanceInfo.distance / 1000).toFixed(2);

    if (5 > kmsDistance) {
      return 8
    } else if (5 < kmsDistance && kmsDistance < 20) {
      return 13
    } else if (20 < kmsDistance && kmsDistance < 30) {
      return 18
    } else if (30 < kmsDistance && kmsDistance < 45) {
      return 35
    } else if (45 < kmsDistance && kmsDistance < 100) {
      return 69
    } else if (50 < kmsDistance && kmsDistance < 400) {
      return 79
    } else {
      return 200
    }
  }

  if(deliveryTransportType === 'van') {
    const kmsDistance = (distanceInfo.distance / 1000).toFixed(2);

    if (5 > kmsDistance) {
      return 39
    } else if (5 < kmsDistance && kmsDistance < 20) {
      return 49
    } else if (20 < kmsDistance && kmsDistance < 30) {
      return 65
    } else if (30 < kmsDistance && kmsDistance < 45) {
      return 79
    } else if (45 < kmsDistance && kmsDistance < 100) {
      return 89
    } else if (50 < kmsDistance && kmsDistance < 400) {
      return 99
    } else {
      return 200
    }
  }
  if (distanceInfo.distance) {
    const kmsDistance = (distanceInfo.distance / 1000).toFixed(2);
    if (5 > kmsDistance) {
      return 8

    } else if (5 < kmsDistance && kmsDistance < 20) {
      return 13
    } else if (20 < kmsDistance && kmsDistance < 30) {
      return 18
    } else if (30 < kmsDistance && kmsDistance < 45) {
      return 35
    } else if (45 < kmsDistance && kmsDistance < 100) {
      return 69
    } else if (100 < kmsDistance && kmsDistance < 200) {
      return 99
    } else {
      const extra = (kmsDistance - 25) * 1.39;
      let roundedPrice = Number.parseFloat(25 + extra).toFixed(1);
      if (roundedPrice > 200) {
        roundedPrice = 200;
      }
      return roundedPrice
    }
  }
  return 9
};